.subscribe {
    padding: 100px 0 100px 0;
    background: var(--slate);
}

.subscribe-container {
    padding-bottom: 80px;
    color: var(--white);

    @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: center;

        .section-left {
            max-width: 100% !important;
            padding: 0 40px !important;
            margin-top: 20px;
        }

        .section-right {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .section-left, .section-right {
        display: block;

        .subscribe-text {
            font-size: 20px;
            line-height: 1.4em;

            @media screen and (max-width: 480px) {
                font-size: 16px;
            }
        }

        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--white);        
        }

        .section-title {

            @media screen and (max-width: 480px) {
                font-size: 100px;
                text-align: right;
                position: relative;
                top: -325px;
            }

            @media screen and (min-width: 480px) and (max-width: 600px) {
                font-size: 120px;
                text-align: right;
                position: relative;
                top: -310px;
            }
        }

        .input-container {
            height: 175px;
            margin-top: 110px;
            width: 100%;

            @media screen and (max-width: 600px) {
                margin-top: -60px;
                height: auto;
            }

            .indie-input {
                background: transparent;
                color: var(--white);
                width: 100%;
                height: 20px;
                padding: 10px;
                font-size: 18px;
                font-weight: normal;
                font-family: "Raleway", sans-serif !important;
                outline: none;
                border-bottom: 2px var(--green) solid;
                border-top: none;
                border-left: none;
                border-right: none;

                
                @media screen and (max-width: 960px) {
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }

        .button-container {
            margin-top: 0;
            text-align: left;

            @media screen and (max-width: 600px) {
                margin-top: 60px;
            }

            @media screen and (max-width: 960px) {
                left: 65px;
            }

            .indie-btn {
                width: 250px;
                
                @media screen and (max-width: 600px) {
                    width: 170px;
                }

                &::before {
                    width: calc(100% + 36%);
                    right: -400px;

                    @media screen and (max-width: 600px) {
                        width: calc(100% - 8%);
                        right: -114px;
                    }

                    @media screen and (min-width: 600px) and (max-width: 960px) {
                        width: calc(100% + 25%);
                        right: -400px;
                    }

                    
                    @media screen and (min-width: 960px) and (max-width: 1060px) {
                        width: calc(100% + 50%);
                        right: -400px;
                    }

                    @media screen and (min-width: 1060px) and (max-width: 1120px) {
                        width: calc(100% + 46%);
                        right: -400px;
                    }
                }
            }
        }
    }

}

