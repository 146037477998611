.visit-top {
    padding: 300px 0 100px 0;

    @media screen and (max-width: 600px) {
        padding: 0 0 100px 0;
    }

}

.visit-top-container {
    padding: 100px 0 0 0;

    @media screen and (max-width: 600px) {
        padding: 0px;
    }

    .section-left {
        margin-top: -300px;
    }

    .section-right {
        z-index: -5;
    }

    
    @media screen and (min-width: 1000px) and (max-width: 1300px) {
        .section-wholesale {
            margin-left: 120px;
        }
    }    

    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        align-items: center;

        .section-left, .section-right {
            max-width: 100% !important;
            margin: 0;
        }

        .section-left {
            margin-top: 85px;
            padding: 0 40px !important;
        }
    }

    .section-left, .section-right {
        display: block;

        .visit-text {
            font-size: 20px;
            line-height: 1.4em;
            //max-width: 450px;

            
            @media screen and (max-width: 480px) {
                font-size: 16px;
            }
        }

        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--black);        
        }

        .section-title {
            margin-bottom: -20px;
            text-align: left;
            line-height: 0;

            @media screen and (max-width: 600px) {
                font-size: 110px;
            }
        }

        .store-info-text {
            width: 100%;
            justify-content: space-between;

            ul {
                display: flex;
                padding: 0;
                li {
                    list-style: none;
                    margin: 0 0 0 50px;

                    &:first-child {
                        margin-right: 50px;
                    }

                    @media screen and (max-width: 480px) {
                        margin: 0;

                        &:first-child {
                            margin-right: 50px;
                        }
                    }
    
                    h4 {
                        color: var(--green);
                    }
                }
            }

        }

        .button-container {
            text-align: right;
            right: -125px;

            @media screen and (max-width: 600px) {
                right: 30px;
            }

            @media screen and (min-width: 600px) and (max-width: 1000px) {
                text-align: right;
                right: 30px;
            }

            .indie-btn {
                width: 275px;

                @media screen and (max-width: 600px) {
                    width: 200px;
                }

                &::before {
                    width: calc(100% + 58%);
                    left: -535px;

                    @media screen and (max-width: 480px) {
                        left: -100px;
                        width: calc(100% - 22%);
                    }

                    @media screen and (min-width: 480px) and (max-width: 600px) {
                        left: -100px;
                        width: calc(100% - 18%);
                    }
                    
                    @media screen and (min-width: 600px) and (max-width: 700px) {
                        left: -395px;
                        width: calc(100% + 22%);
                    }

                    @media screen and (min-width: 700px) and (max-width: 800px) {
                        left: -395px;
                        width: calc(100% + 20%);
                    }

                    @media screen and (min-width: 800px) and (max-width: 1000px) {
                        left: -395px;
                        width: calc(100% + 18%);
                    }

                    @media screen and (min-width: 1000px) and (max-width: 1300px) {
                        left: -295px;
                        width: calc(100% + 12%);
                    }
                }
            }
        }

        .image-container {
            max-width: 495px;
            max-height: 675px;
            width: 100%;
            height: 100%;
            margin-top: -270px;

            @media screen and (max-width: 600px) {
                margin-top: 0;
            }

            @media screen and (max-width: 600px) {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: auto;
            }

            img {
                width: 100%;
                height: 100%;
                box-shadow: 10px -8px 0px 2px var(--lightGreen);
            }
        }

    }

}
