.contact {
    width: 100%;
    height: auto;
    padding: 250px 0 250px 0;
    background: url(https://indiecoffeeroasters.com/wp-content/uploads/2018/01/Footer-Coffee-Mug-Pourover-Image.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.contact-container {
    padding: 100px 0 100px 0;
    color: var(--white);

    @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: center;

        .section-left, .section-right {
            max-width: 100% !important;
            width: 100% !important;
        }

        .section-left {
            padding: 0 40px !important;
        }
    
    }

    .section-left, .section-right {
        display: block;
        flex: 1 1 50%;
        max-width: 50%;
        margin: 0 50px 0 50px;
        height: auto;
        text-align: left;
        box-sizing: border-box;
        position: relative;

        .contact-text {
            font-size: 20px;
            line-height: 1.4em;

            @media screen and (max-width: 480px) {
                font-size: 16px;
            }

            
            @media screen and (max-width: 960px) {
                margin-bottom: 55px;
            }
        }

        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--white);        
        }

        .input-container {
            height: auto;

            .indie-input, .indie-textarea {
                background: transparent;
                color: var(--white);
                width: 100%;
                height: 20px;
                padding: 10px;
                font-size: 18px;
                font-weight: normal;
                font-family: "Raleway", sans-serif !important;
                outline: none;
                border-bottom: 2px var(--green) solid;
                border-top: none;
                border-left: none;
                border-right: none;
                margin: 10px 0;

                @media screen and (max-width: 960px) {
                    width: 80%;
                }
            }

            .indie-textarea {
                height: 80px;
            }
        }

        .button-container {
            margin-top: 60px;
            text-align: left;

            @media screen and (max-width: 600px) {
                margin-top: 60px;
            }

            @media screen and (max-width: 960px) {
                left: 65px;
            }

            .indie-btn {
                width: 250px;
                
                @media screen and (max-width: 600px) {
                    width: 170px;
                }

                &::before {
                    width: calc(100% + 36%);
                    right: -400px;

                    @media screen and (max-width: 600px) {
                        width: calc(100% - 8%);
                        right: -114px;
                    }

                    @media screen and (min-width: 600px) and (max-width: 960px) {
                        width: calc(100% + 25%);
                        right: -400px;
                    }

                    
                    @media screen and (min-width: 960px) and (max-width: 1060px) {
                        width: calc(100% + 50%);
                        right: -400px;
                    }

                    @media screen and (min-width: 1060px) and (max-width: 1120px) {
                        width: calc(100% + 46%);
                        right: -400px;
                    }
                }
            }
        }
    }

}
