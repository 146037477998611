:root {
  --white: white;
  --black: #000;
  --green: #a9ce91;
  --lightGreen: #a9ce9129;
  --slate: #2a2a2a;
}

html,
body {
  font-family: "Raleway", sans-serif;
}

body {
  overflow-x: hidden;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  flex-direction: row;
}

.body-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.animated {
  width: 100%;
  z-index: 99;

  &.slideInLeft, .slideInRight {
    z-index: 99;
  }
}

.shop,
.wholesale,
.about,
.learn,
.visit,
.subscribe {
  height: auto;
  width: 100%;
  display: block;
  position: relative;
}

.section-title {
  font-size: 150px;
  font-weight: bold;
  letter-spacing: 5px;
  color: var(--lightGreen);
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 65px;
  }
}

.section-sub-header {
  @media screen and (max-width: 480px) {
    font-size: 38px !important;
  }
}

.section-title-underline {
  height: 5px;
  width: 65px;
  background: var(--green);
  border-radius: 10px;

  &.sub-header-underline {
    height: 3px;
    background: var(--green);
    border-radius: 10px;
    width: 350px;
    justify-content: flex-start;
    margin: 5px 0 0 0;

    @media screen and (min-width: 250px) and (max-width: 375px) {
      width: 280px
    }
  }
}

.shop-container,
.wholesale-container,
.learn-container,
.visit-container,
.subscribe-container {
  width: 100%;
  align-items: flex-start;
}

.section-left,
.section-right {
  display: flex;
  flex: 1 1 50%;
  margin: 0 50px 0 50px;
  max-width: 50%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
}

.button-container {
  width: 100%;
  position: absolute;
  z-index: 99;

  .indie-btn {
    height: 70px;
    background: transparent;
    border: none;
    letter-spacing: 3px;
    color: var(--green);
    z-index: 99;
    font-size: 24px;
    border: 1px var(--green) solid;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      background: var(--green);
      color: var(--white);
    }

    &::before {
      content: "";
      height: 1px;
      background-color: var(--green);
      position: absolute;
      top: 50%;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px !important;
      height: 60px !important;
    }
  }
}
