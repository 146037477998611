.about-bottom {
    height: auto;
    width: 100%;
    padding: 100px 0 100px 0;

    .about-bottom-container {

        @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
    
            .section-left, .section-right {
                max-width: 100% !important;
                padding: 0 40px !important;
                margin: 0;
            }
        }

        .section-left,
        .section-right {
            .section-title {
                text-align: left;
                margin-left: -90px;
                margin-bottom: -88px;

                @media screen and (max-width: 600px) {
                    font-size: 115px;
                    margin-bottom: -110px;
                }
            }

            img {
                box-shadow: 10px -8px 0px 2px var(--lightGreen);
            }

            display: flex;

            .section-sub-header {
                font-size: 48px;
                font-weight: bold;
                margin: 20px 0 20px 0;
                color: var(--black);
            }
        }
    }

    .about-bottom-imgs-container {
        z-index: 100;


        @media screen and (max-width: 480px) {
            flex-direction: column;
        }

        .img-container {
            max-width: 200px;
            position: relative;
            margin: -30px 10px 0 10px;

            @media screen and (max-width: 480px) {
                margin: 10px 0 10px 0;
            }

            @media screen and (max-width: 1000px) {

                margin: 0 10px 0 10px;
            }

            .img-hover {
                background: #a9ce919c;
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                visibility: visible;
                position: absolute;
                left: 10px;
                opacity: 0;
                top: 0px;
                transition: all 0.5s ease-in-out;

                &:hover {
                    top: 10px;
                    opacity: 1;
                }

                .team-text {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 18px;

                    p {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: .2rem;
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }
}
