.navMenu {
    top: 0px;
    position: sticky;
    background: var(--white);
    border-bottom: solid var(--green) 3px;
    height: 50px;
    text-align: left;
    width: 100%;
    z-index: 150;
    flex-direction: row;

    &.hidden {
        visibility: hidden;
    }

    &.sticky {
        position: sticky;
        top: 0px;
        width: 100%;
        
        animation: slideInDown; 
        animation-duration: .6s; 
    }

    .hamburger-icon-container {
        visibility: hidden;
    }

    .logo {
        height: 40px;
        width: 40px;
        padding-left: 20px;

        img {
            height: 100%;
            vertical-align: middle;
        }
    }
    
    
    .menuItems-link-wrapper {
        transition: height 0.3s ease-out;
        width: 100%;
        text-align: left;
        max-width: 1200px;

        a {
            text-decoration: none;
        }

        .menu-link {
            display: inline;
            color: var(--black);
            padding: 0 20px;
            font-size: 16px;
            text-transform: uppercase;
            transition: all 0.5s;
            cursor: pointer;
            font-weight: bold;

            &:hover {
                color: var(--green)
            }

            &.active {
                color: var(--green);
            }
        }
    }
}

@media screen and (max-width: 968px) {
    .navMenu {

        display: flex;
        align-items: flex-start;
        padding-left: 20px;
        justify-content: space-between;

        .logo {
            padding-top: 3px;
            padding-left: 0;
        }

        .hamburger-icon-container {
            height: 40px;
            width: 40px;
            padding-top: 12px;
            text-align: center;
            vertical-align: middle;
            visibility: visible;
            margin-right: 26px;

            .anticon {
                color: var(--black);
                display: inline;
                font-size: 20px;
                cursor: pointer;
                transition: all 0.5s ease-in-out; 
                
                animation: zoomIn;
                animation-duration: 1s;

                &:hover {
                    color: var(--green)
                }
            }
        }
        
        .menuItems-link-wrapper {
            transition: all 0.5s ease-in-out;
            width: 100%;
            text-align: left;
            max-width: 100vw;
            position: absolute;
            background: #000000c2;
            height: 0px;
            visibility: hidden;
            top: 50px;
            left: 0;

            .menu-link {
                visibility: hidden;
            }

            &.active {
                transition: all 0.5s ease-in-out;
                padding-top: 20px;
                height: 35vh;
                visibility: visible;

                .menu-link {
                    display: block;
                    color: var(--white);
                    padding: 5px 0 5px 25px;
                    font-size: 16px;
                    text-transform: uppercase;
                    transition: all 0.5s;
                    cursor: pointer;
                    font-weight: bold;
                    text-align: left;
                    visibility: visible;

                    &:hover {
                        color: var(--green)
                    }

                    &.active {
                        color: var(--green);
                    }
                }
            }

        }
    }
}