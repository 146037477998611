.shop {
    padding: 40px 0 100px 0;
    background: var(--white);

    @media screen and (max-width: 600px) {
        padding: 0 0 50px 0;
    }
}

.shop-container {
    padding: 50px 0 50px 0;

    @media screen and (min-width: 1000px) and (max-width: 1300px) {
        .section-shop {
            margin-left: 120px;
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;

        .section-left {
            max-width: 100% !important;
            padding: 0 40px !important;
        }

        .section-right {
            display: none !important;
        }
    }

    .section-right {
        z-index: -5;
    }

    .section-left,
    .section-right {
        display: block;

        .shop-text {
            font-size: 20px;
            line-height: 1.4em;
            max-width: 450px;

            @media screen and (max-width: 480px) {
                font-size: 16px;
              }
        }

        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--black);
        }

        .button-container {
            margin-top: 103px;
            text-align: right;
            right: -125px;

            @media screen and (max-width: 600px) {
                right: 0px;
                margin-top: 89px;
            }

            @media screen and (min-width: 600px) and (max-width: 1000px) {
                margin-top: 102px;
                text-align: right;
                right: 30px;
            }

            .indie-btn {
                width: 250px;

                @media screen and (max-width: 600px) {
                    width: 170px;
                }

                &::before {
                    width: calc(100% + 64%);
                    left: -535px;

                    @media screen and (max-width: 600px) {
                        left: -100px;
                        width: calc(100% - 10%);
                    }

                    @media screen and (min-width: 600px) and (max-width: 1000px) {
                        left: -395px;
                        width: calc(100% + 42%);
                    }

                    @media screen and (min-width: 1000px) and (max-width: 1300px) {
                        left: -295px;
                        width: calc(100% + 20%);
                    }
                }
            }
        }

        .section-title {
            margin-bottom: 0;
            margin-top: -50px;

            @media screen and (max-width: 600px) {
                font-size: 120px;
                text-align: right;
            }

            .coffee-beans {
                position: relative;
                top: 110px;
                height: 100px;
                left: -8px;
                width: 260px;

                @media screen and (max-width: 600px) {
                    left: -15px;
                }
            }
        }

        .image-container {
            width: 450px;
            height: 450px;
            z-index: -5;
            box-shadow: 10px -8px 0px 2px var(--lightGreen);

            img {
                width: 450px;
                height: 450px;
            }
        }

        .image-container::before {
            content: "";
            position: absolute;
            z-index: 2;
            width: 450px;
            height: 450px;
            background: #0000003d;
        }
    }
}
