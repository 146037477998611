.banner {

    height: 700px; 
    width: 100vw;

    @media screen and (max-width: 800px) {
        height: 100vh;
    }

    .banner-container {
        height: 700px;
        width: 100%;
        left: 0;
        top: 0;
        position: fixed;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        -webkit-transform: translate3d(0, 0, 0);

        @media screen and (max-width: 800px) {
            height: 100vh;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .banner-container::before {
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: blur(2px); /* apply the blur */
        backdrop-filter: blur(2px);         /* apply the blur */
        pointer-events: none; /* make the pseudo class click-through */
        background: #0000003d;
      }
    
    .logo-wrapper {
        z-index: 99;
        height: 430px;
        width: 450px;

        animation: fadeIn;
        animation-duration: 1.4s;

        img {
            position: relative;
            left: -6px;
        }

        @media screen and (max-width: 480px) {
            height: 360px;
            width: 365px;
        }
    }


    .social-wrapper {
        width: 160px;
        height: 50px;
        z-index: 99;
        background: #000000b8;
        justify-content: space-evenly;

        .social-link {
            width: 45px;
            height: 45px;
            color: var(--green);
            font-size: 28px;
            text-align: center;
            transition: all 0.5s ease-in-out;
            padding-top: 12px;

            &:hover {
                color: var(--white);
            }
        }
    }

}