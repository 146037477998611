.wholesale {
    padding: 100px 0 150px 0;
    background: var(--slate);

    .section-title {
        margin-bottom: -130px;
        text-align: center;
    
        @media screen and (min-width: 375px) and (max-width: 480px) {
            font-size: 45px;
            margin-bottom: -40px !important;
        }
    
        @media screen and (min-width: 480px) and (max-width: 600px) {
            font-size: 55px;
            margin-bottom: -40px !important;
        }
    
        
        @media screen and (min-width: 600px) and (max-width: 800px) {
            font-size: 80px;
            margin-bottom: -40px !important;
        }
    
        @media screen and (min-width: 800px) and (max-width: 1050px) {
            font-size: 120px;
            margin-bottom: -40px !important;
        }
    
    }
}

.wholesale-container {
    padding: 50px 0 50px 0;

    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        align-items: center;

        .section-partner {
            max-width: 100% !important;
            padding: 0 40px !important;
        }

        .section-right {
            width: auto !important;
            max-width: 100% !important;
        }
    }

    .section-right {
        margin-top: 15px;
    }

    .section-partner, .section-right {
        color: var(--white);
        display: block;
    
        .wholesale-text {
            font-size: 20px;
            line-height: 1.4em;

            @media screen and (max-width: 480px) {
                font-size: 16px;
              }
        }
    
        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--white);        
        }

        .button-container {
            margin-top: 70px;
            text-align: left;

            @media screen and (max-width: 480px) {
                margin-top: 295px;
              }

            @media screen and (min-width: 480px) and (max-width: 600px) {
                margin-top: 325px;
            }

            @media screen and (min-width: 800px) and (max-width: 1000px) {
                margin-top: 235px;
            }

            @media screen and (min-width: 600px) and (max-width: 800px) {
                margin-top: 275px;
            }

            .indie-btn {
                width: 250px;      

                @media screen and (max-width: 600px) {
                    width: 205px;
                }

                &::before {
                    width: calc(100% + 22%);
                    right: -370px;                

                    @media screen and (max-width: 600px) {
                        width: calc(100% - 10%);
                        right: -160px;
                    }

                    @media screen and (min-width: 1000px) and (max-width: 1300px) {
                        width: calc(100% - 13%);
                        right: -160px;
                    }

                    @media screen and (min-width: 800px) and (max-width: 1000px) {
                        width: calc(100% - 11%);
                        right: -160px;
                    }

                    @media screen and (min-width: 600px) and (max-width: 800px) {
                        width: calc(100% - 15%);
                        right: -160px;
                    }
                }
            }
        }

        .image-container {
            width: 650px;

            @media screen and (min-width: 1050px) and (max-width: 1300px) {
                width: 535px;
            }

            @media screen and (min-width: 1000px) and (max-width: 1050px) {
                width: 455px;
            }

            @media screen and (min-width: 600px) and (max-width: 800px) {
                width: 455px;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            img {
                filter: invert(1);

                @media screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }
    }
}

