.about {
    padding: 100px 0 100px 0;
    background: var(--white);

    .section-title {
        margin-bottom: -130px;
    
        @media screen and (max-width: 480px) {
            font-size: 90px;
            margin-bottom: -80px !important;
        }
        
        @media screen and (min-width: 480px) and (max-width: 600px) {
            margin-bottom: -100px !important;
            font-size: 120px;
        }
    }

    .about-section {
        margin: 0 50px;

        @media screen and (max-width: 600px) {
            margin: 0;
        }
        
        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--black);  
            margin-bottom: -40px;      

            @media screen and (max-width: 600px) {
                padding: 0 30px 0 30px;
            }
        }
            
        .imgs-container {
            padding: 50px 0 50px 0;
            flex-wrap: wrap;
            width: 100%;

            .rec-carousel-wrapper {
                width: 100%;
                @media screen and (min-width: 1080px) {
                    display: none;
                }

                .rec-carousel {
                    height: 450px !important;
                    width: 100%;
                }
                
                .rec-slider {
                    width: 100%;
                }
                
                .rec-slider-container {
                    margin: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .rec-dot {
                    width: 14px;
                    height: 14px;
                    box-shadow: none;
                    background-color: var(--lightGreen);
                }
                
                .rec-dot_active {
                    background-color: var(--green);
                }

            }

            .img-container {
                width: 300px;
                height: 300px;
                display: inline-block;
                margin: 10px;

                @media screen and (max-width: 1080px) {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .about-text {
            font-size: 20px;
            line-height: 1.4em;
            height: auto;
            min-height: 130px;

            
            @media screen and (max-width: 480px) {
                font-size: 16px;
                padding: 0 30px 40px 30px;
              }

            @media screen and (min-width: 480px) and (max-width: 600px) {
                padding: 0 30px 40px 30px;
            }

            .button-container {
                margin-top: 30px;
                text-align: right;
                right: 155px;
                position: relative;
    
                @media screen and (max-width: 600px) {
                    right: 50px;
                    margin-top: 60px;
                }
    
                .indie-btn {
                    width: 250px; 

                        
                    @media screen and (max-width: 600px) {
                        width: 205px;
                    }
        
                    &::before {
                        left: -255px;
                        width: calc(100% + 2%);

                        @media screen and (max-width: 480px) {
                            width: calc(100% - 52%);
                            left: 0px;
                        }

                        @media screen and (min-width: 480px) and (max-width: 600px) {
                            width: calc(100% - 18%);
                            left: -130px;
                        }

                        
                        @media screen and (min-width: 600px) and (max-width: 800px) {
                            width: calc(100% - 18%);
                            left: -130px;
                        }

                        @media screen and (min-width: 800px) and (max-width: 1000px) {
                            width: calc(100% - 14%);
                            left: -130px;
                        }

                        @media screen and (min-width: 1000px) and (max-width: 1150px) {
                            width: calc(100% - 11%);
                            left: -130px;
                        }
                    }
                }
            }
        }
    }

    
}