.visit {
    padding: 100px 0 100px 0;
    background: var(--white);
}

.visit-container {
    padding: 50px 0 50px 0;

    @media screen and (min-width: 1000px) and (max-width: 1300px) {
        .section-left {
            margin-left: 120px !important;
        }
    }

    @media screen and (max-width: 960px) {
        flex-direction: column-reverse;
        align-items: center;
        display: flex;

        .section-left {
            max-width: 100% !important;
            padding: 0 40px !important;
        }

        .section-right {
            max-width: 100% !important;
        }
    }

    .section-right {
        z-index: -5;
    }

    .section-left, .section-right {
        display: block;

        .visit-text {
            font-size: 20px;
            line-height: 1.4em;
            max-width: 450px;

            @media screen and (max-width: 480px) {
                font-size: 16px;
            }
        }

        .section-sub-header {
            font-size: 48px;
            font-weight: bold;
            margin: 20px 0 20px 0;
            color: var(--black);        
        }

        .button-container {
            margin-top: 130px;
            text-align: right;
            right: -130px;

            @media screen and (max-width: 600px) {
                right: -20px;
                margin-top: 100px;
            }

            @media screen and (min-width: 600px) and (max-width: 960px) {
               margin-top: 50px;
               right: -80px;
            }

            .indie-btn {
                height: 70px;
                width: 400px;

                @media screen and (max-width: 600px) {
                    width: 300px;
                }

                &::before {
                    width: calc(100% + 34%);
                    left: -540px;

                    @media screen and (min-width: 250px) and (max-width: 480px) {
                        left: -60px;
                        width: calc(100% - 66%);
                    }

                    @media screen and (min-width: 480px) and (max-width: 600px) {
                        left: -115px;
                        width: calc(100% - 44%);
                    }

                    @media screen and (min-width: 600px) and (max-width: 960px) {
                        left: -375px;
                        width: calc(100% - 1%);
                    }

                    @media screen and (min-width: 960px) and (max-width: 1200px) {
                        left: -250px;
                        width: calc(100% - 28%);                }
                }
            }
        }

        .section-title {
            @media screen and (max-width: 600px) {
                font-size: 120px;
                text-align: right;
            }
        }

        .image-container {
            height: 450px;
            width: 450px;
            z-index: -5;

            @media screen and (max-width: 960px) {
                width: 100%;
                height: auto;
            }

            img {
                height: 450px;
                width: 450px;
                box-shadow: 10px -8px 0px 2px var(--lightGreen);

                @media screen and (max-width: 960px) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .image-container::before {
            content: "";
            position: absolute;
            z-index: 2;
            height: 450px;
            width: 450px;
            background: #0000003d;

            @media screen and (max-width: 960px) {
                display: none;
            }
          }
    }

}
