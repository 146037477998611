.values {
    width: 100%;
    height: 340px;
    background: var(--slate);

    @media screen and (max-width: 920px) {
        height: auto;
    }

    .values-container {
        width: 100%;
        height: auto;
        color: var(--white);

        .section-title {
            margin-bottom: -200px;
            top: -32px;
            text-align: left;
            left: -30px;
            position: relative;
            
            @media screen and (max-width: 600px) {
                margin-bottom: -135px;
                font-size: 100px;
                top: -16px;
                left: 0;
            }
        }

        .carousel-items-container {
            padding: 50px 0 50px 0;
            width: 100%;

            @media screen and (max-width: 920px) {
                flex-direction: column;
            }

            .section-left {
                max-width: 25%;
            }

            .section-left, .section-right {

                margin: 0;

                .text-container {
                    min-height: 190px;
                }
                
                .section-sub-header {
                    font-size: 48px;
                    font-weight: bold;
                    margin: 20px 0 20px 0;
                    color: var(--white);        
                }
            }
        }

        .carousel-img {
            min-width: 170px;
            max-width: 180px;

            img {
                border: 1px solid var(--green);
                border-radius: 50%;
                padding: 7px;
            }
        }

        .rec-carousel-wrapper {
            width: 100%;
            
            // .rec {
            //     height: 100%;
            // }

            .rec-carousel {
                //max-height: 340px !important;
                width: 100%;
            }
            
            .rec-slider {
                width: 100%;
            }
            
            .rec-slider-container {
                margin: 0;
                margin-top: 30px;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .rec-pagination {
                position: relative;
                right: -500px;
                top: -190px;
                flex-direction: column;

                @media screen and (max-width: 650px) {
                    right: -200px;
                }

                @media screen and (min-width: 650px) and (max-width: 900px) {
                    right: -300px;
                }

                @media screen and (min-width: 900px) and (max-width: 1100px) {
                    right: -400px;
                }
            }

            .rec-dot {
                width: 14px;
                height: 14px;
                box-shadow: none;
                background-color: var(--lightGreen);
            }
            
            .rec-dot_active {
                background-color: var(--green);
            }

        }
    }
}